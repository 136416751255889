import React, { FC, lazy, Suspense } from 'react';
import { MOBILE_WIDGET_WIDTH } from '../constants/sizes';
import { useMobileOverlay } from './MobileOverlayProvider';
import MobileSpinnerOverlay from '../mobile/components/mobile-spinner-overlay/mobile-spinner-overlay';

const MobileOverlay = lazy(
  () => import(/* webpackChunkName: "mobile-overlay" */ './mobile-overlay'),
);

export const MobileOverlayModal: FC = () => {
  const { isMobileOverlayOpen } = useMobileOverlay();

  if (!isMobileOverlayOpen) {
    return null;
  }

  return (
    <div
      data-hook="mobile-overlay-modal"
      style={{
        position: 'fixed',
        width: MOBILE_WIDGET_WIDTH,
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999,
        margin: '0 auto',
      }}
    >
      <Suspense fallback={<MobileSpinnerOverlay />}>
        <MobileOverlay />
      </Suspense>
    </div>
  );
};
